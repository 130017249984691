import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-fetch';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: 'https://okprftnqznexlcjt7o5lcnjp34.appsync-api.us-east-1.amazonaws.com/graphql?',
  fetch,
  headers: {
    "x-api-key": "da2-k572svnwizg5xnrcdhvbjadxxq",
  }
});

export const client = new ApolloClient({
  cache,
  link,
});

export const host = 'https://secure.ziptips.io';